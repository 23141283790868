.creation-facility {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 700;
}

.creation-facility__container {
  opacity: 1;
  width: 100px;
  height: 100px;
  position: absolute;
  transform-origin: 0 0;
  background-color: #fff;
}

.custom-pin.facility-pin.creation-icon {
  z-index: 9999 !important;
}

.creation-facility-area {
  z-index: 9999;
  position: absolute;
  background-color: #000;
}

.draw-icon {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #fff;
  &.dry_land {
    background-color: #73AB93;
  }
  &.roads {
    background-color: rgba(255, 186, 86, 0.5);
  }
}