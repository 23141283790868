.left-sidebar__top {
  margin-bottom: 8px;
  position: relative;
  border-radius: 4px;
  background-color: #303a48;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  height: 50px;

  &:hover {
    .leftSideBar__title_text {
      max-width: 346px;
    }

    .edit-project-name {
      opacity: 1;
    }
  }
}

.leftSideBar__title_text {
  top: 50%;
  left: 63px;
  font-size: 18px;
  font-family: 'LatoWebMedium';
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  transform: translateY(-50%);
  white-space: nowrap;
  max-width: 368px;
  overflow: hidden;
  text-overflow: ellipsis;

  &.shadow {
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 1);
  }
}

.edit-project-name {
  top: 50%;
  opacity: 0;
  right: 16px;
  cursor: pointer;
  position: absolute;
  transition: all 0.25s ease;
  transform: translateY(-50%);
}