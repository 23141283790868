@import "./colors.scss";

@mixin bg {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.relative {
  position: relative;
}

// TOGGLE //
.main-row-toggle {
  background-color: rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid #1e242d;
  color: #cfd8dd;
  font-size: 12px;
  line-height: 12px;
  font-family: 'LatoWebBold';
  cursor: pointer;
  position: relative;
  z-index: 1;
  height: 32px;
}

.open-projects-main-row.hide-row .field-row__showRow-icon {
  filter: opacity(20%);
}

.common-component-handlers {
  right: 0;
  top: 50%;
  display: flex;
  position: absolute;
  align-items: center;
  transform: translateY(-50%);

  .field-row__remove {
    margin-right: 8px;
  }

  .icon.networkInfo {
    opacity: 0;
  }
}

.row-toggle {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  height: 32px;
  position: relative;
  z-index: 9;
  font-family: 'LatoWeb';
  transition: all 0.25s ease;
  border-bottom: 1px solid #1E242D;

  &.task-row {
    padding-left: 32px;
  }

  &:hover,
  &.active {
    background-color: #3e4b5d;
  }
}

.arrow {
  margin: 8px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-image: url(./assets/images/group_expanded.svg);
  @include bg;
}

.hide {
  .arrow {
    background-image: url(./assets/images/group_collapsed.svg);
  }
}

.tasks {
  .row-toggle__text {
    margin: 0;
    height: 32px;
    padding: 8px 0px 8px 24px;
  }
}

.row-toggle__text {
  padding: 8px 0;
  line-height: 14px;
  font-size: 14px;
  max-width: 318px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  width: 100%;
}

.network {
  .main-row-toggle__text {
    max-width: 280px;
  }
}

.main-row-toggle__text {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  color: #CFD8DD;
  font-family: 'LatoWebBold';
  max-width: 310px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main-toggle-title {
  width: calc(100% - 38px);
}

// ***TOGGLE*** //
.close {
  background-image: url(./assets/images/close.svg);
}

.main-title {
  margin-top: 1px;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  font-family: 'LatoWebBold';
}

.switch-container {
  label {
    background-color: #202A38;
    width: 44px;
    height: 24px;
    display: block;
    border-radius: 12px;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: #8495AC;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      left: 4px;
      transition: all 0.25s ease;
    }
  }

  input:checked + label::after {
    left: calc(100% - 20px);
    background-color: $tiffany;
  }

  input {
    display: none;
  }
}

.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.25s ease;

  &.edit {
    background-image: url(./assets/images/edit.svg);
  }

  &.error {
    background-image: url(./assets/images/errorStatus.svg);
  }

  &.process {
    background-image: url(./assets/images/status_task_in_process.svg);
  }

  &.done {
    background-image: url(./assets/images/status_task_done.svg);
  }

  &.not_relevant_task {
    background-image: url(./assets/images/not_relevant_task.svg);
  }

  &.networkInfo {
    background-image: url(./assets/images/networkInfo.svg);
  }

  &.repeatIcon {
    background-image: url(./assets/images/repeatTask.svg);
  }
}

.download-icon {
  background-image: url(./assets/images/download.svg);
}

.remove-icon {
  background-image: url(./assets/images/delete.svg);
}

.remove-alert__content-row {
  td {
    &:first-child {
      padding-left: 16px;
      padding-right: 7px;
    }

    &:not(:first-child) {
      width: 100%;
    }
  }

  &:last-child {
    td {
      &:first-child {
        padding-left: 16px;
      }

      padding-bottom: 12px;
    }
  }

  &:first-child {
    td {
      padding-top: 14px;
    }
  }

  &:not(:last-child) {
    td {
      padding-bottom: 7px;
    }
  }
}

/// scrollarea
.scrollarea-content {
  margin: 0;
  padding: 0; // overflow: hidden;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollarea-content:focus {
  outline: 0;
}

.scrollarea {
  position: relative;
  overflow: hidden;
  height: 100%;

  &.scrollarea-logs {
    height: calc(100% - 49px);
  }
}

.scrollarea .scrollbar-container {
  position: absolute;
  background: none;
  opacity: 1;
  z-index: 9999;
}

.scrollarea .scrollbar-container.horizontal {
  width: 100%;
  left: 0;
  bottom: 0;
  padding-top: 4px;
  padding-bottom: 8px;
  cursor: pointer;

  .scrollbar {
    height: 4px;
    position: relative;
    background-color: transparent;

    &::after {
      content: '';
      position: absolute;
      background: #202A38;
      border-radius: 4px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 8px;
      right: 8px;
    }
  }
}

.scrollarea .scrollbar-container.vertical {
  height: 100%;
  width: 10px;
  right: 0;
  top: 0;
}

.scrollarea .scrollbar-container.vertical .scrollbar {
  width: 4px;
  height: 20px;
  min-height: 18px;
  position: relative;
  background-color: transparent;

  &::after {
    content: '';
    position: absolute;
    background: #202A38;
    right: 0;
    min-height: 16px;
    border-radius: 4px;
    left: 0;
    top: 15%;
    bottom: 15%;
  }
}

.alert-textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  resize: none;
  min-height: 32px;
}

.icon {
  &.well {
    background-image: url(./assets/images/objIco_well_cluster.svg);
  }

  &.quarry {
    background-image: url(./assets/images/objIco_quarry.svg);
  }

  &.compressor_station,
  &.gas_disposal_well {
    background-image: url(./assets/images/objIco_gas.svg);
  }

  &.oil_treatment_plant,
  &.booster_pump_station {
    background-image: url(./assets/images/objIco_upn_dns.svg);
  }

  &.stop_valve_station,
  &.water_disposal_well,
  &.commissioning_station,
  &.multiphase_pump_station,
  &.raw_water_intake_facility,
  &.central_gathering_facility,
  &.oil_transfer_pumping_station,
  &.modular_cluster_pump_station,
  &.preliminary_water_removal_unit {
    background-image: url(./assets/images/objIco_water_oil.svg);
  }

  &.transformer_station,
  &.plant_power_station,
  &.outer_source_of_power_supply {
    background-image: url(./assets/images/objIco_electricity.svg);
  }

  &.facility {
    background-image: url(./assets/images/objIco_other.svg);
  }
}
