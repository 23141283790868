.left-sidebar__top-row {
  height: 50px;
  position: relative;
  padding: 16px 16px 15px;
  border-bottom: 1px solid #1E242D;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.left-sidebar__bottom-row {
  padding: 16px;
  border-top: 1px solid #242C36;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.16);
  button {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}