@mixin bg {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.project-version {
  top: 114px;
  right: 48px;
  font-size: 11px;
  line-height: 13px;
  position: absolute;
  color: rgba($color: #CFD8DD, $alpha: 0.5)
}

.projects {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.projects__item-bg {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  background-color: #3E4B5D;
}

.projects__item {
  z-index: 0;
  padding: 0 16px;
  font-size: 16px;
  cursor: pointer;
  position: relative;

  &:hover {
    .projects__item-bg {
      opacity: 1;
    }

    .projects__col-desc-title-text {
      max-width: calc(100% - 60px);
    }

    .wellfield-icons {
      opacity: 1;
    }
  }

  .projects__col-row {
    padding: 16px 0;
  }
}

.wellfield-icons {
  opacity: 0;
  position: absolute;
  top: 23px;
  right: 16px;

  .download-icon {
    margin-right: 12px;
  }
}

.projects__col {
  border-radius: 4px;
  background-color: #303a48;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.2);
  vertical-align: top;
  display: inline-block;
  width: 500px;
  max-height: 80vh;

  &:first-child {
    margin-right: 16px;
  }
}

.projects__col_left {
  padding: 0 40px;
}

.projects__icon {
  width: 340px;
  height: 60px;
  margin: 0 auto;
  background-image: url(../../assets/images/logo.svg);
  @include bg;
}

button.projects__button {
  padding: 12px 24px;
}

.projects__title-row {
  padding-top: 59px;
  padding-bottom: 57px;
  position: relative;
  border-bottom: 1px solid #242c36;
}

.projects__text {
  display: inline-block;
  vertical-align: middle;
  margin: 0 16px;
  font-size: 14px;
  opacity: 0.5;
  color: #cfd8dd;
}

.projects__bottom-row {
  border-top: 1px solid rgba(207, 216, 221, 0.18);
  padding: 39px 0 40px 0;
  text-align: center;
}

.projects__col-row {
  padding: 16px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: #242c36;
  }

  &.title {
    z-index: 9;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  }
}

.projects__col-img {
  width: 96px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
  border-radius: 2px;
  background-size: cover;
  background-image: url(../../assets/images/preview.png);
}

.projects__col-desc {
  display: inline-block;
  vertical-align: top;
  padding: 8px 0;
  height: 80px;
  position: relative;
  width: calc(100% - 112px);

  &.wellfield {
    height: 68px;
    padding: 20px 0;
    width: 100%;

    .projects__col-desc-title {
      margin-bottom: 3px;
    }

    .projects__col-date {
      bottom: auto;
      position: relative;
    }
  }
}

.projects__col-desc-title-text {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.projects__col-desc-title {
  font-size: 14px;
  font-family: 'LatoWebMedium';
  line-height: 14px;
  font-style: normal;
  color: #fff;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: block;
}

.projects__col-desc-subtitle {
  font-size: 12px;
  font-family: 'LatoWebMedium';
  font-style: normal;
  opacity: 0.8;
  color: #cfd8dd;
}

.projects__col-date {
  opacity: 0.5;
  font-size: 11px;
  color: #cfd8dd;
  position: absolute;
  bottom: 8px;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.projects__container {
  width: 1016px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.open-projects {
  max-width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: #303a48;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.open-projects__top-row {
  padding: 17px;
  background-color: #39424f;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #242c36;
}

.open-projects__close-button {
  float: right;
  position: relative;
  cursor: pointer;
  width: 16px;
  height: 16px;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 1px;
    background-color: rgba(207, 216, 221, 0.45);
  }

  &::after {
    top: 8px;
    left: 0;
    transform: rotate(45deg);
  }

  &::before {
    top: 8px;
    left: 0;
    transform: rotate(-45deg);
  }
}

.open-projects__title {
  font-size: 16px;
  color: #fff;
  font-family: 'LatoWebBold';
  float: left;
}

.open-projects-search {
  height: 63px;
  padding: 16px;
  border-bottom: 1px solid #1e242d;
}

.projectsRowTitle {
  font-size: 12px;
  font-family: 'LatoWebBold';
  color: #cfd8dd;
  padding: 9px 9px 9px 37px;
  text-transform: uppercase;
  border-bottom: 1px solid #1e242d;
  background-color: rgba(0, 0, 0, 0.16);
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    left: 17px;
    top: 8px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    border-right: 2px solid rgba(255, 255, 255, 0.4);
    transition: all 0.25s ease;
  }

  &.hide {
    &::after {
      top: 11px;
      transform: rotate(-45deg);
    }
  }
}

.open-projects__col {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.openProjectImg {
  height: 535px;
  background-color: hotpink;
}

.open-project__bottom-row {
  padding: 16px;
  border-top: 1px solid #242c36;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.projects-list-wrapper {
  max-height: 650px;
  display: block;
}

.projects-list-container {
  height: calc(75vh - 50px);

  &.wellfield {
    .wellfield-icons {
      top: 16px;
    }

    .projects__col-row {
      padding: 0;
    }
  }
}

.open-projects__container {
  position: relative;
}

.open-projects-first-col {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.open-projects-list {
  height: calc(100% - 63px);
}

.row-toggle__text-active {
  position: relative;

  &::after {
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    height: 18px;
    position: absolute;
    transform: translateY(-50%);
    background-color: rgba($color: #D5E6FD, $alpha: 0.3);

  }
}

.open-projects {
  .row-toggle__text {
    padding-left: 36px;
    max-width: 100%;
  }
}

.open-projects__open-button {
  margin-right: 16px;
}

.new-project__main {
  .DataFieldsRow {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.projects-count {
  margin-left: 50px;
}

.draw-project-area {
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #77F4E8;
}