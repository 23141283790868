@charset "UTF-8";
.task-info-top-row .main-title,
.task-info-top-row .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px; }

.left-sideBar__options {
  display: inline-block;
  padding: 9px; }
  .left-sideBar__options.menu {
    height: 50px; }
    .left-sideBar__options.menu::after {
      top: 50%;
      right: -1px;
      transform: translateY(-50%);
      content: '';
      position: absolute;
      width: 1px;
      height: 16px;
      border-radius: 20px;
      background-color: rgba(132, 149, 172, 0.4);
      opacity: 0; }
    .left-sideBar__options.menu.full::after {
      opacity: 1; }

.leftSideBar__mainContainer {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 400px;
  display: inline-block;
  vertical-align: top;
  height: calc(100vh - 74px);
  min-height: 606px;
  background-color: #303a48;
  border-left: 1px solid rgba(30, 36, 45, 0.6);
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.2); }
  .leftSideBar__mainContainer .scrollarea {
    height: calc(100% - 115px); }
  .leftSideBar__mainContainer form {
    height: 100%; }

.task-priority {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 32px;
  transform: translateY(-50%); }
  .task-priority.highPriority {
    background-image: url(../../assets/images/highPriority.svg); }
  .task-priority.mediumPriority {
    background-image: url(../../assets/images/mediumPriority.svg); }
  .task-priority.lowPriority {
    background-image: url(../../assets/images/lowPriority.svg); }

.left-sidebar__bottom {
  height: calc(100vh - 74px);
  min-height: 606px;
  position: relative;
  border-radius: 4px;
  background-color: #303a48; }
  .left-sidebar__bottom.full {
    background-color: #38424f; }

.leftSideBar {
  position: absolute;
  z-index: 9999;
  top: 8px;
  left: 8px;
  bottom: 8px; }

.left-sidebar {
  position: absolute;
  z-index: 9999;
  top: 8px;
  left: 8px;
  bottom: 8px; }

.left-sidebar__row {
  padding: 16px; }

.left-sidebar__projects {
  height: calc(100% - 50px);
  border-top: 1px solid #1E242D; }
  .left-sidebar__projects .scrollarea {
    max-height: calc(100% - 65px); }
  .left-sidebar__projects .row-toggle {
    padding-left: 32px; }

.left-sidebar__main-container {
  width: 400px;
  height: 100%;
  border-radius: 4px;
  background: #303A48;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3); }

.left-sidebar__top-fields {
  height: calc(100% - 65px); }

.openLeftSideBar {
  position: absolute;
  bottom: 8px;
  left: 25px;
  transform: rotate(0deg) translate(-50%, -25%);
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/m_expand_panel.svg); }
  .openLeftSideBar.open {
    transform: rotate(180deg) translate(50%, 25%); }

.main-container__top-row {
  height: 50px;
  position: relative;
  padding: 16px 16px 15px;
  border-bottom: 1px solid #1E242D; }
  .main-container__top-row .main-title {
    max-width: calc(100% - 26px);
    overflow: hidden;
    text-overflow: ellipsis; }

.menu {
  position: relative; }

.menu__container {
  border-radius: 4px;
  z-index: 99;
  position: absolute;
  background-color: #444e5d;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  min-width: 200px;
  left: 8px;
  top: 45px; }

.menu__row {
  padding-bottom: 16px; }
  .menu__row:first-child {
    padding-top: 22px; }
  .menu__row:not(:first-child) {
    padding-top: 16px; }
  .menu__row:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.24); }

.menu__title {
  letter-spacing: 0.3px;
  color: #ffffff;
  font-size: 14px;
  padding-top: 2px;
  line-height: 14px;
  white-space: nowrap;
  display: block;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px; }
  .menu__title:not(:last-child) {
    padding-bottom: 20px; }

.menu__triangle {
  position: relative;
  z-index: -1; }
  .menu__triangle::before {
    content: '';
    position: absolute;
    width: 0;
    top: -5px;
    left: 6px;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #444e5d; }

.field-row {
  font-size: 14px;
  color: #fff;
  height: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  line-height: 32px; }

.showRow {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 8px;
  transition: all 0.25s ease;
  opacity: 1; }
  .showRow:hover {
    opacity: 1; }
  .showRow.hide .field-row__showRow-icon {
    opacity: 0; }

.colorLine {
  width: 6px;
  height: 31px;
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #242C36;
  border-left: 1px solid #242C36; }
  .colorLine .colorLineBlack,
  .colorLine .colorLineColor {
    height: 100%;
    display: block; }
  .colorLine .colorLineBlack {
    width: 1px;
    background-color: #242C36; }
  .colorLine .colorLineColor {
    width: 4px; }

.field-row__lock {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-left: 1px solid rgba(0, 0, 0, 0.24);
  vertical-align: middle;
  position: relative; }

.field-row__lock-icon {
  width: 32px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../assets/images/lock_object.svg);
  background-size: 16px 16px;
  opacity: 1;
  transition: all 0.25s ease; }
  .field-row__lock-icon.hide {
    opacity: 0; }

.field-row__showRow-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/show_object.svg);
  position: relative; }
  .field-row__showRow-icon.hide {
    opacity: 0; }

.input-label {
  display: block;
  line-height: 12px;
  padding-top: 4px;
  font-size: 14px;
  opacity: 0.5;
  color: #CFD8DD;
  margin-bottom: 4px;
  white-space: nowrap; }

.taskSettingsContainerRow {
  padding: 16px;
  position: relative; }
  .taskSettingsContainerRow:not(:first-child)::after {
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    height: 1px;
    border-radius: 20px;
    background-color: #242c36;
    left: 50%;
    transform: translateX(-50%);
    top: -1px; }

.taskSettignsContainer {
  height: 100%;
  position: relative; }

.graph-edge-length .DataFieldsContainer::after {
  white-space: nowrap;
  content: 'min — 10 м, max — 500 м';
  position: absolute;
  top: 50%;
  transform: translate(100%, -50%);
  line-height: 16px;
  font-size: 12px;
  color: #CFD8DD;
  opacity: 0.5;
  right: -16px; }

.coefs-row {
  border-top: 1px solid #242C36;
  padding-top: 17px; }

.coefs-row__title {
  font-size: 14px;
  font-family: 'LatoWebBold';
  line-height: 16px;
  color: #CFD8DD; }

.graph-edge-length__linear {
  margin-bottom: 16px; }

.DataFieldsRow {
  display: inline-block;
  position: relative; }
  .DataFieldsRow.quarter {
    width: calc(25% - 12px);
    margin-top: 15px; }
    .DataFieldsRow.quarter:not(:nth-child(4n + 4)) {
      margin-right: 16px; }
  .DataFieldsRow.half {
    width: calc(50% - 8px); }
    .DataFieldsRow.half:nth-child(2n + 1) {
      margin-right: 16px; }
  .DataFieldsRow.middle {
    margin-right: 16px;
    width: calc(67% - 16px); }
  .DataFieldsRow.extrasmall {
    width: 33%; }
  .DataFieldsRow.wide {
    width: 100%; }
  .DataFieldsRow.small {
    width: calc(50% - 8px); }
    .DataFieldsRow.small:nth-child(2n + 1) {
      margin-right: 16px; }

.leftSideBarFields {
  height: 100%; }
  .leftSideBarFields .scrollarea {
    height: calc(100% - 50px); }
  .leftSideBarFields.infoCard, .leftSideBarFields.high_voltage, .leftSideBarFields.quarries, .leftSideBarFields.facility_placement, .leftSideBarFields.linear_cost, .leftSideBarFields.createArea, .leftSideBarFields.oil_gathering_line, .leftSideBarFields.oil_pipe, .leftSideBarFields.high_pressure_water_pipe, .leftSideBarFields.low_pressure_water_pipe, .leftSideBarFields.high_pressure_gas_pipe, .leftSideBarFields.low_pressure_gas_pipe, .leftSideBarFields.roads, .leftSideBarFields.createFacility, .leftSideBarFields.createLinearObject {
    height: calc(100vh - 139px); }

.taskSettingsContainer {
  height: 100%; }

.taskSettingsContainerRow.taskSettingsContainerBottom {
  position: absolute;
  left: 50px;
  bottom: 0;
  right: 0;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.24); }

.left-container-title {
  float: left;
  font-size: 12px;
  line-height: 16px;
  font-family: 'LatoWebBold';
  color: #cfd8dd;
  padding-left: 37px;
  position: relative;
  cursor: pointer; }
  .left-container-title::before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    left: 18px;
    top: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    transition: all 0.25s ease;
    background-image: url(../../assets/images/group_collapsed.svg); }
  .left-container-title.show::before {
    background-image: url(../../assets/images/group_expanded.svg); }

.taskTitleIcon {
  float: right;
  width: 16px;
  height: 16px;
  background-color: hotpink; }

.left-container-icon {
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 0;
  padding-right: 8px;
  z-index: 9;
  line-height: 32px; }

.clearfix {
  clear: both; }

.left-container-sub-title {
  font-size: 14px;
  color: #fff;
  padding-left: 37px;
  float: left;
  line-height: 16px;
  max-width: calc(100% - 40px); }

.main-title__icon {
  margin-left: 4px; }

.left-container-row {
  cursor: pointer;
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid #1e242d; }
  .left-container-row.active {
    background-color: #3e4b5d; }
    .left-container-row.active .left-container-title {
      color: #77F4E8; }

.createTaskContainer {
  height: 100%; }

.createTaskContainerRow {
  padding: 16px 16px 0; }
  .createTaskContainerRow.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.24); }

.tasks-container .left-container-title {
  text-transform: uppercase; }

.checkBoxContainer:not(:last-child) {
  margin-bottom: 16px; }

.checkBoxInput {
  display: none; }
  .checkBoxInput:disabled + .checkBoxLabel::before {
    background-color: transparent;
    border: 1px solid #202A38;
    border-radius: 2px; }
  .checkBoxInput:checked + .checkBoxLabel::after {
    opacity: 1; }

.checkBoxLabel {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  padding-left: 26px;
  position: relative;
  display: block; }
  .checkBoxLabel::before, .checkBoxLabel::after {
    content: '';
    position: absolute; }
  .checkBoxLabel::before {
    width: 18px;
    height: 18px;
    left: 0px;
    top: 0px;
    border-radius: 2px;
    background-color: #202a38; }
  .checkBoxLabel::after {
    left: 3px;
    top: 4px;
    width: 12px;
    height: 9px;
    transition: all 0.25s ease;
    opacity: 0;
    background-image: url(../../assets/images/checkbox.svg); }

.taskButtons {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 16px; }
  .taskButtons button {
    width: 18px;
    height: 18px;
    background-color: hotpink;
    margin-right: 10px; }

.taskButtonsLine {
  display: inline-block;
  width: 1px;
  height: 16px;
  border-radius: 20px;
  background-color: #242c36;
  margin-right: 10px; }

.card {
  width: 400px;
  background-color: #303a48;
  border-radius: 4px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5); }

.card-main {
  padding: 16px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10; }

.remove-alert__question-title {
  color: #fff;
  font-size: 14px;
  line-height: 16px; }

.splash-screen {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(48, 58, 72, 0.16);
  z-index: 99998; }

.remove-alert {
  top: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 99999; }
  .remove-alert.card {
    width: 440px; }

.remove-alert__button {
  margin-right: 16px; }

.remove-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }

.task-toggle .main-row-toggle {
  padding-left: 16px; }

.task-toggle__icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.4;
  z-index: -1; }
  .task-toggle__icon.gathering_centers {
    background-image: url(../../assets/images/oil_gathering_line.svg); }
  .task-toggle__icon.linear_cost {
    background-image: url(../../assets/images/linear_cost.svg); }
  .task-toggle__icon.roads {
    background-image: url(../../assets/images/task_calculate_road_network.svg); }
  .task-toggle__icon.facility_placement {
    background-image: url(../../assets/images/task_calculate_po.svg); }
  .task-toggle__icon.linearRoads {
    background-image: url(../../assets/images/task_calculate_road_network.svg); }
  .task-toggle__icon.quarries {
    background-image: url(../../assets/images/task_calculate_quarrying.svg); }
  .task-toggle__icon.oil_pipe, .task-toggle__icon.oil_gathering_line, .task-toggle__icon.high_pressure_water_pipe, .task-toggle__icon.low_pressure_water_pipe, .task-toggle__icon.high_pressure_gas_pipe, .task-toggle__icon.low_pressure_gas_pipe {
    background-image: url(../../assets/images/task_calculate_pipeline_network.svg); }
  .task-toggle__icon.high_voltage {
    background-image: url(../../assets/images/task_calculate_vl.svg); }

.fieldsComponent.linearRoads, .fieldsComponent.linearPipes, .fieldsComponent.createFacility, .fieldsComponent.facility {
  padding-bottom: 68px;
  height: calc(100% - 50px); }

.fieldsComponent.objects, .fieldsComponent.tasks, .fieldsComponent.areas, .fieldsComponent.taskInfo, .fieldsComponent.linear {
  height: calc(100vh - 124px); }

.fieldsComponent .field-row__lock {
  position: absolute;
  top: 0;
  right: 0; }

.fieldsComponent .row-toggle {
  padding-right: 41px; }

.fieldsComponent .field-row__text {
  padding-left: 24px; }

.taskInfo .scrollarea {
  max-height: calc(100% - 165px); }

.common-component-handlers .download-icon {
  opacity: 0; }

.repeatIcon,
.download-task-icon {
  opacity: 0; }

.repeatIcon {
  margin-left: 12px; }

.row-toggle:hover .repeatIcon,
.row-toggle:hover .download-icon,
.row-toggle:hover .set-view,
.row-toggle:hover .download-task-icon,
.row-toggle:hover .icon.networkInfo,
.row-toggle:hover .field-row__remove {
  opacity: 1; }
  .row-toggle:hover .repeatIcon + .icon::before,
  .row-toggle:hover .download-icon + .icon::before,
  .row-toggle:hover .set-view + .icon::before,
  .row-toggle:hover .download-task-icon + .icon::before,
  .row-toggle:hover .icon.networkInfo + .icon::before,
  .row-toggle:hover .field-row__remove + .icon::before {
    opacity: 1; }

.main-row-toggle:hover .remove-metaclass,
.main-row-toggle:hover .icon.networkInfo {
  opacity: 1; }

.main-row-toggle:hover .main-row-toggle__text {
  max-width: 300px; }

.field-row__remove {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/delete.svg);
  opacity: 0;
  margin-left: 12px;
  transition: all 0.25s ease; }
  .field-row__remove + .icon {
    margin-left: 17px;
    position: relative; }
    .field-row__remove + .icon::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 32px;
      left: -9px;
      opacity: 0;
      transition: all 0.25s ease;
      background-color: #242C36;
      top: 50%;
      transform: translateY(-50%); }

.create-task-container_common-fields {
  border-bottom: 1px solid #242C36; }

.task-info .create-task-toggle-row {
  background-color: transparent; }

.task-info .create-task-toggle-row__title:not(:last-child) {
  margin-bottom: 8px; }

.create-task-toggle-row {
  border-bottom: 1px solid #242c36;
  margin-top: 16px; }
  .create-task-toggle-row .create-task-main-container {
    margin: 0;
    padding: 8px 0 0 26px; }
    .create-task-toggle-row .create-task-main-container.hide {
      display: none; }
  .create-task-toggle-row .create-task-row .checkBoxLabel {
    padding-bottom: 16px; }
  .create-task-toggle-row:not(:last-child) {
    margin-bottom: 8px; }

.create-task-toggle-rows {
  overflow: hidden; }

.create-task-toggle-row__title {
  border-top: 1px solid #242c36; }
  .create-task-toggle-row__title .checkBoxLabel {
    padding-left: 26px;
    margin: 16px 0; }

.checkBoxSpan {
  padding-top: 2px;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle; }

.createTaskToggleContainer {
  padding-bottom: 16px;
  border-bottom: 1px solid #242c36; }

.set-view {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/focus_on_selected.svg);
  opacity: 0;
  transition: all 0.25s ease; }
  .set-view:last-child {
    margin-right: 8px; }

.field-text-row .row-toggle__text {
  padding-left: 32px; }

.taskSettingsContainerRow button:first-child {
  margin-right: 16px; }

.left-sidebar__fields {
  height: calc(100% - 50px); }
  .left-sidebar__fields form {
    height: 100%; }

.toggle-sublayer .toggle-sublayer .main-row-toggle {
  background-color: #2C3542; }

.toggle-sublayer .toggle-sublayer .toggle-title {
  padding-left: 42px; }

.toggle-sublayer .toggle-sublayer .row-toggle__text {
  margin-left: 21px; }

.toggle-sublayer .toggle-sublayer .main-row-toggle__text {
  font-family: 'LatoWeb';
  color: #FFFFFF; }

.toggle-sublayer .toggle-sublayer .row-toggle:hover .row-toggle__text {
  max-width: 270px; }

.toggle-sublayer .toggle-title {
  padding-left: 21px; }

.toggle-sublayer .row-toggle__text {
  padding-left: 54px; }

.toggle-sublayer .toggle-sublayer .row-toggle__text {
  max-width: 300px; }

.toggle-sublayer .row-toggle:hover .row-toggle__text {
  max-width: 250px; }

.toggle-sublayer .main-row-toggle__text {
  font-size: 14px;
  line-height: 14px;
  text-transform: none; }

.switch-results {
  background-color: #394454;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  border-bottom: 1px solid #242C36; }

.switch-results__line {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 2px;
  margin: 0 8px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/dotted-line.svg); }

.switch-results__title {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle; }

.switch-results__switch {
  display: inline-block;
  vertical-align: middle; }

.preload-text {
  margin-top: 42px;
  line-height: 14px;
  font-size: 14px;
  color: #CFD8DD;
  opacity: 0.5;
  text-align: center; }
