.row-toggle__text {
  &.task-toggle-text {
    &:hover {
      max-width: 250px;
    }
  }
  &.row-toggle-facility {
    &:hover {
      max-width: 275px;
    }
  }
}

.row-toggle__text {
  &.toggle-project-row {
    &:hover {
      max-width: 305px;
    }
  }
}