@import '../../colors.scss';
.new-wellfield-container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 9999;
}
.new-wellfield__logs-container {
  height: 300px;
  background: #202A38;
  border-radius: 4px;
  padding: 10px;
}

.new-wellfield__logs {
  padding-top: 16px;
  margin-top: 16px;
  border-top: 1px solid #242C36;
}

.dropzone__container {
  width: 100%;
  height: 100%;
  padding: 16px;
  line-height: 32px;
}

.dropzone__logs-button {
  float: right;

  input {
    display: none;
  }
}

.dropzone__container-icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;

  &.error {
    background-image: url(../../assets/images/dropzone__container-icon-error.svg);
  }

  &.warnings {
    background-image: url(../../assets/images/dropzone__container-icon-warnings.svg);
  }

  &.success {
    background-image: url(../../assets/images/dropzone__container-icon-success.svg);
  }

  &.canceled {
    background-image: url(../../assets/images/dropzone__container-icon-cancel.svg);
  }
}

.dropzone__logs-container {
  display: inline-block;
  vertical-align: middle;
}

.dropzone__logs-result {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 18px;
}

.dropzone__logs-result-warnings {
  color: $warning;
}

.new-wellfield__dropzone {
  margin-top: 18px;
}

.new-wellfield__create-button {
  margin-right: 13px;
}

.new-wellfield__logs-row {
  display: block;
  user-select: text;

  &:not(:last-child) {
    margin-bottom: 13px;
  }
}

.new-wellfield__logs-message {
  font-size: 14px;
  color: #fff;
  display: inline-block;
  width: calc(100% - 140px);
  user-select: text;

  &.has_errors {
    color: $warning;

    &.finished {
      color: $error;
    }
  }
}

.new-wellfield__logs-time {
  font-size: 14px;
  color: #8495AC;
  margin-right: 16px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
  width: 124px;
  padding-top: 2px;
}

.logs-area__padding-right {
  padding-right: 15px;
}

.logs-container__padding-right{
  padding-right: 4px;
}

.new-wellfield__logs-container .scrollarea .scrollbar-container.vertical .scrollbar {
  &::after {
    background: #444E5D;
  }
}

.download-indicator {
  width: 518px;
  height: 4px;
  background: #495566;
  border-radius: 4px;
  margin-bottom: 5px;
  &::after {
    margin:-24px 0 0 -7px;
    padding: 0;

    float: right;
    font-size: 12px;
    line-height: 16px;
    content: attr(label-content);
  }
}
.download-indicator::-webkit-progress-bar {
  background: #495566;
  border-radius: 4px;
}
.download-indicator::-webkit-progress-value {
  border-radius: 4px;
  background: #77F4E8;
}
.download-indicator__label {
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
}
.download-indicator__wrap {
  position: relative;
}