 // BUTTONS //
 .primary-button {
   background-color: #3e4b5d;
   color: #ffffff;
   font-size: 14px;
   line-height: 16px;
   font-family: 'LatoWebSemibold';
   padding: 7px 16px;
   border-radius: 4px;
   border: 1px solid transparent;
   position: relative;
   z-index: 9;
   &::after {
     content: '';
     position: absolute;
     top: -1px;
     right: -1px;
     bottom: -1px;
     left: -1px;
     z-index: -1;
     transition: all 0.25s ease;
     border-radius: 4px;
   }
   &:hover {
     &::after {
       background-color: rgba(132, 149, 172, 0.4);
     }
   }
   &:active {
     &::after {
       background-color: rgba(40, 49, 61, 0.24);
     }
   }
   &:disabled {
     border: solid 1px rgba(255, 255, 255, 0.1);
     background-color: #303a48;
     color: #cfd8dd;
   }
 }

 .secondary-button,
 .primary-button {
   cursor: pointer;
   &.disabled {
     pointer-events: none;
     color: rgba($color: #CFD8DD, $alpha: .3);
     background: #303A48;
     border: 1px solid rgba(255, 255, 255, 0.1);
   }
 }

 .secondary-button {
   border-radius: 4px;
   background-color: #303a48;
   border: solid 1px #3e4b5d;
   padding: 7px 16px;
   line-height: 16px;
   color: #ffffff;
   font-family: 'LatoWebSemibold';
   position: relative;
   z-index: 9;
   font-size: 14px;
   transition: all 0.25s ease;
   &::after {
     content: '';
     position: absolute;
     top: -1px;
     right: -1px;
     bottom: -1px;
     left: -1px;
     z-index: -1;
     transition: all 0.25s ease;
     border-radius: 4px;
   }
   &:hover {
     &::after {
       background-color: rgba(132, 149, 172, 0.2);
     }
     border: solid 1px rgba(255, 255, 255, 0.1);
   }
   &:active {
     &::after {
       background-color: rgba(56, 68, 85, 0.5);
     }
     border: solid 1px rgba(40, 49, 61, 0.24);
   }
   &:disabled {
     color: #cfd8dd;
     background-color: #303a48;
border: solid 1px rgba(255, 255, 255, 0.1);
}

} // BUTTONS //