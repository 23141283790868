.ruler-marker {
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #8495ac;
  transition: none;
  &.leaflet-marker-icon {
    z-index: 9999;
  }
}

.ruler.leaflet-popup a.leaflet-popup-close-button {
  top: 50%;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
}