@import "../../colors.scss";

.result-table-top {
  background-color: #3E4B5D;

  th {
    font-size: 14px;
    text-align: left;
    line-height: 16px;
    color: $grayWithOpacity;
    border-bottom: 1px solid #242C36;
    min-width: 150px;
    padding: 8px 16px;

    &:not(:last-child) {
      border-right: 1px solid #242C36;
    }

    &.small {
      padding: 8px;
      min-width: auto;
    }
  }
}

.result-table {
  table-layout: fixed;
}

.task-complete__main-container {
  .scrollarea {
    width: 100%;
    height: 259px;
  }
}

.result-table-top__linear-cost {
  th {
    white-space: nowrap;

    &.text-center {
      text-align: center;
    }

    &:last-child {
      width: 100%;
    }
  }
}

.result-table__body {
  td {
    color: #fff;
    font-size: 14px;
    text-align: left;
    padding: 7px 16px;
    line-height: 14px;
    position: relative;
    border-right: 1px solid #242C36;
    border-bottom: 1px solid #242C36;

    &:not(:first-child) {
      text-align: right;
    }

    &.small {
      padding: 7px 8px;
    }
  }
}

.result-table__body-common-fields {
  td {
    font-family: 'LatoWebBold';
  }
}

.result-table__body-linear-cost {
  td {
    white-space: nowrap;
  }
}

.result-table__initial-position,
.result-table__optimized-position {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-left: 1px solid #242C36;
    border-right: 1px solid #242C36;
    width: 4px;
    height: 100%;
  }
}

.result-table__initial-position {
  &::before {
    background-color: #F5A623;
  }
}

.result-table__optimized-position {
  &::before {
    background-color: #6B4EFF;
  }
}

.result-table__accept-results {
  text-align: center;
  padding: 64px 0;
}

.result-table__accept-results-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 17px;
  color: #CFD8DD;
  margin-right: 16px;
}

th,
td {
  &.result-table__color-line {
    min-width: 5px;
    padding: 0;
    border-right: 1px solid #242C36;
  }
}

.calculated_tmp {
  &.hide {
    opacity: 0;
  }
}

.linear-results-table {
  display: flex;
}

.task-complete__container {
  max-height: 100%;
}

.graph-tooltip {
  padding: 6px 12px;
  border-radius: 4px;
  background: #444E5D;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.graph-tooltip__text {
  color: #CFD8DD;
  font-size: 12px;
  line-height: 16px;
  font-family: 'LatoWebBold';
}

.gathering-centers__container {
  padding: 8px;
}

.gathering-centers__row {
  border-radius: 4px;
  background-color: #394454;
  padding: 0 24px;
  height: 48px;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;

  &:hover {
    background-color: rgba(32, 42, 56, 0.8);

    .gathering-centers__row-button {
      opacity: 1;
    }
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.gathering-centers__title {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  font-family: 'LatoWebBold';
}

.gathering-centers__sub-title {
  font-size: 14px;
  line-height: 18px;
  color: rgba(207, 216, 221, 0.5);
}

.gathering-centers__color-line {
  position: absolute;
  width: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.gathering-centers__facilities {
  font-size: 14px;
  line-height: 18px;
  color: #fff;
}

.gathering-centers__title-container {
  min-width: 10%;
  padding-right: 30px;
}

.gathering-centers-inline {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.gathering-centers__row-button {
  opacity: 0;
  top: 50%;
  right: 8px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.is-applied-title {
  color: #8495AC;
  font-size: 14px;
  line-height: 16px;
  font-family: 'LatoWebMedium';
  position: relative;
  margin-right: 12px;

  &::before {
    content: '';
    position: absolute;
    left: -24px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/images/confirm.svg);
  }
}
