@import '../../colors.scss';

.handlers {
  padding: 8px;
  white-space: nowrap;
}

.choose-objects__all {
  margin-bottom: 20px;
}

.choose-objects-component {
  position: relative;
  height: 48px;
}

.facility_placement {
  .choose-objects-buttons-container {
    min-width: 469px;
  }
}

.roads,
.quarries,
.linear_cost {
  .choose-objects-buttons-container {
    min-width: 228px;
  }
}

.choose-objects-buttons-container {
  height: 100%;
  min-width: 525px;
}

.handlers,
.choose-objects-container {
  left: calc(50% + 229px);
  bottom: 8px;
  z-index: 9999;
  border-radius: 4px;
  position: absolute;
  text-align: center;
  background-color: #303a48;
  transform: translateX(-50%);
}

.choose-objects__semibold {
  font-family: 'LatoWebMedium';
}

.choose-objects-container {
  &.single-button {
    .choose-objects__button {
      width: 228px;
    }

    .choose-objects__bg {
      right: 8px;
    }

    .choose-objects__menu.active-0 {
      left: auto;
      right: -88px;
    }
  }

  &.gathering_centers {
    .choose-objects__button {
      width: 320px;
    }

    .choose-objects-buttons-container {
      min-width: 320px;
    }
  }

  &.oil_pipe,
  &.oil_gathering_line,
  &.low_pressure_gas_pipe,
  &.high_pressure_gas_pipe,
  &.low_pressure_water_pipe,
  &.high_pressure_water_pipe {
    .choose-objects__button {
      width: 250px;
    }
  }

  &.facility_placement,
  &.roads {
    .choose-objects__button {
      width: 234px;
    }
  }

  &.high_voltage {
    .choose-objects__button {
      width: 262px;
    }
  }
}

.handlers__first-title {
  font-size: 14px;
  color: #ffffff;
  line-height: 24px;
  font-family: 'LatoWebMedium';
}

.handlers__button {
  font-size: 14px;
  font-family: 'LatoWebMedium';
  color: #8495ac;
  border-radius: 4px;
  padding: 8px 12px;

  &.active {
    color: #ffffff;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #27303c;

    .handlers__icon {
      &.rotate {
        background-image: url(../../assets/images/drawingPanel_rotateActive.svg);
      }

      &.drag {
        background-image: url(../../assets/images/drawingPanel_movingActive.svg);
      }

      &.magnet {
        background-image: url(../../assets/images/drawingPanel_magnetActive.svg);
      }

      &.repaint {
        background-image: url(../../assets/images/drawingPanel_repaintActive.svg);
      }

      &.contour {
        background-image: url(../../assets/images/drawingPanel_editcontourActive.svg);
      }

      &.connection {
        background-image: url(../../assets/images/drawingPanel_connectionPointActive.svg);
      }
    }
  }
}

.handlers__icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 8px;

  &.repaint {
    background-image: url(../../assets/images/drawingPanel_repaint.svg);
  }

  &.magnet {
    background-image: url(../../assets/images/drawingPanel_magnet.svg);
  }

  &.contour {
    background-image: url(../../assets/images/drawingPanel_editcontour.svg);
  }

  &.rotate {
    background-image: url(../../assets/images/drawingPanel_rotate.svg);
  }

  &.drag {
    background-image: url(../../assets/images/drawingPanel_moving.svg);
  }

  &.connection {
    background-image: url(../../assets/images/drawingPanel_connectionPoint.svg);
  }
}

.handlers__button-text {
  display: inline-block;
  vertical-align: middle;
}

.choose-objects__button {
  &:not(:last-child) {
    margin-right: 1px;

    .choose-objects__button-container {
      position: relative;

      &::before {
        top: 50%;
        transform: translateY(-50%);
        right: -2px;
        content: '';
        width: 1px;
        height: 16px;
        border-radius: 20px;
        position: absolute;
        background: rgba(132, 149, 172, 0.4);
      }
    }
  }
}

.choose-objects__button-container {
  line-height: 48px;
  font-size: 0;
  width: 100%;
  border-radius: 4px;
  position: relative;
  display: block;
  text-align: left;

  span {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
  }

  &.active {
    background: #27303C;
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.121716);
  }

  &:last-child {
    margin-right: 8px;
    margin-left: 4px;
  }
}

.choose-objects__button__white {
  color: #fff;
}

.choose-objects__button-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 16px;
  height: 16px;
  font-size: 0;
  line-height: 0;
  transform: translateY(-50%);

  path {
    transition: all 0.25s ease;
  }
}

.choose-objects__button-text {
  color: #8495AC;
  margin-left: 44px;
}

.choose-objects__menu {
  top: 10px;
  border-radius: 8px;
  padding: 20px;
  background: #444E5D;
  position: absolute;
  color: #fff;
  font-size: 14px;
  text-align: left;
  line-height: 16px;
  transform: translateY(-100%);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  z-index: 1;
  cursor: pointer;

  &.active-0 {
    left: calc(50% - 66px);
  }

  &.active-1 {
    right: -88px;
  }

}

.choose-objects__menu-triangle {
  left: 0;
  bottom: -25px;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #444E5D;
  transform: rotate(45deg);
}

.choose-objects__menu-triangle-container {
  position: relative;
}

.choose-objects__menu-icon {
  top: 50%;
  width: 16px;
  height: 16px;
  right: 20px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: all .25s ease;
  background-size: contain;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-image: url(../../assets/images/MoreActions.svg);
}

.choose-objects__bg {
  top: 8px;
  bottom: 8px;
  border-radius: 4px;
  position: absolute;
  background: #27303C;
  transition: left 0.2s ease-in-out, right 0.2s ease-in-out 0.1s;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.121716);

  &.active-0 {
    left: 8px;
    right: calc(50% + 4px);

    & + div .choose-objects__button:first-child {
      path {
        fill: $tiffany;
      }

      .choose-objects__menu-icon {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &.active-1 {
    left: calc(50% + 4px);
    right: 8px;

    & + div .choose-objects__button:last-child {
      path {
        fill: $activeSecond;
      }

      .choose-objects__menu-icon {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
