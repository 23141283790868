@import '../../../colors.scss';

.create-task-row {
  position: relative;
}
.create-task-row-content {
  font-size: 12px;
  display: inline;
  vertical-align: middle;
  line-height: 16px;
  color: rgba(207, 216, 221, 0.5);
}

.DataFields {
  line-height: 16px;
  border-radius: 2px;
  background-color: #202a38;
  width: 100%;
  padding: 8px 16px 8px 8px;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  caret-color: $tiffany;
  font-family: 'LatoWebMedium';

  &:disabled {
    color: #fff;
    border-radius: 2px;
    border: solid 1px #202a38;
    background-color: transparent;
  }

  & + .line {
    position: absolute;
    height: 100%;
    left: 0;
    border-radius: 2px;
    transition: all 0.25s ease;
    width: 2px;
    background-color: transparent;
  }

  &:active,
  &:focus {
    & + .line {
      background-color: #8549ec;
    }
  }
}

.valueWithDemValue {
  display: inline-block;
  max-width: 93%;
  font-size: 14px;
  pointer-events: none;
  color: transparent;
  background-color: transparent;
}

.DataFieldsContainer {
  position: relative;
}

.valueWithDem {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 8px;
  white-space: nowrap;
  pointer-events: none;
}

.valueWithDemDem {
  font-size: 12px;
  opacity: 0.5;
  color: #fff;
  padding-left: 8px;
}

.select-container {
  position: relative;
}

.error {
  .data-fields-error-line {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 9;
    left: 0;
    width: 2px;
    border-radius: 2px;
    background-color: $error;
  }

  .DataFields {
    color: $error;
  }
}

.custom-select {
  cursor: pointer;
  position: relative;
  z-index: 999;
  height: auto !important;
}

.custom-select__triangle {
  position: absolute;
  width: 6px;
  height: 3px;
  top: 50%;
  right: 13px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.custom-select__container {
  position: relative;
  z-index: 999;

  &.active {
    .custom-select__triangle {
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.custom-select-value {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-family: 'LatoWebMedium';
  padding: 8px;
  color: #fff;

  &:hover {
    background-color: #3E4B5D;
  }
}

.custom-select-values {
  border: 1px solid #191F26;
  background-color: #202A38;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
