.create-task-row__title {
  color: #CFD8DD;
  font-size: 14px;
  line-height: 16px;
  font-family: 'LatoWebBold';
  margin-top: 4px;
  margin-bottom: 16px;
}

.create-task-container {
  margin: 0 16px 0 15px;
  padding: 16px 0;
}

.create-task-main-container {
  margin: 0 16px;
  position: relative;
  padding: 12px 0 16px 0;
}

.create-task-toggle__line {
  top: -8px;
  left: 8px;
  width: 2px;
  bottom: 16px;
  position: absolute;
  background-color: #242C36;
}

.create-task-row {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.choose-object-area {
  cursor: pointer;
  text-align: center;
  padding: 12px 22px;
  border-radius: 4px;
  background-color: transparent;
  font-family: 'LatoWebSemibold';
  border: dashed 1px rgba(170, 192, 221, 0.4);
}

.choose-object-area__text {
  opacity: 0.5;
  font-size: 14px;
  color: #AAC0DD;
  width: 100%;
}

.select-all-objects-button {
  float: right;
}

.choose-objects-label {
  float: left;
}

.select-all-objects-button,
.choose-objects-label {
  opacity: 0.5;
  font-size: 14px;
  color: #AAC0DD;
}

.choose-object-area__remove-objects {
  top: 50%;
  z-index: 9;
  right: 16px;
  opacity: 0.5;
  display: none;
  font-size: 14px;
  color: #AAC0DD;
  position: absolute;
  transform: translateY(-50%);
}

.choose-object-container {
  position: relative;

  .choose-object-area {
    min-height: 60px;
    position: relative;
  }

  &.active {
    .choose-object-area__remove-objects {
      display: block;
    }

    .choose-object-area {
      text-align: left;
      line-height: 34px;
      border: 1px solid #202a38;
      background-color: #202a38;

      span.choose-object-area__text {
        font-size: 14px;
        overflow: hidden;
        color: #FFFFFF;
        max-width: 260px;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        opacity: 1;

        &::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          background-image: url(../../../assets/images/selectedObject.svg);
        }
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.nowrap-label {
  label {
    white-space: nowrap;
  }
}

.choose-objects__button {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.card-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.alert-row {
  padding: 16px 0 16px 40px;
  border-top: 1px solid #242C36;
  border-bottom: 1px solid #242C36;
  background-color: #394454;
}

.alert-row__title {
  font-size: 14px;
  color: #fff;
  position: relative;
  line-height: 16px;

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/images/notRelevantTask.svg);
  }
}

.alert-row__title-bold {
  font-family: 'LatoWebBold';
}
