div.__react_component_tooltip {
  border-radius: 4px !important;
  background: #192433 !important;
  z-index: 99999 !important;
  padding: 6px 12px !important;
  max-width: 300px;
  min-height: 28px;
  font-size: 0;
  &.show {
    opacity: 1 !important;
  }

  .tooltip-text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
    font-family: 'LatoWebBold';
  }
}

.tooltip-text__title {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  font-family: 'LatoWebBold';
  letter-spacing: 0.3px;
}

.tooltip-text__row {
  font-size: 12px;
  line-height: 16px;
  padding-left: 24px;
  position: relative;
  letter-spacing: 0.3px;
  font-family: 'LatoWebBold';
  &::first-letter {
    text-transform: uppercase;
  }
  &::before {
    content: '';
    left: 12px;
    width: 4px;
    height: 4px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
  }
  &:not(:last-child) {
    margin-bottom: 2px;
  }
}